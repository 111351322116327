import classNames from "classnames";
import type { CustomButtonProps } from "@components/Button";
import { Button } from "@components/Button";
import FanstaDialog from "@components/FanstaDialog";
import styles from "./index.module.css";
import type { FC } from "react";

type ButtonProps = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
} & CustomButtonProps;

type Props = {
  isShown: boolean;
  title: string;
  message: string;
  continueButton: ButtonProps;
  abortButton?: ButtonProps;
  abortsOnBackgroundClick?: boolean;
};

export const ConfirmationModal: FC<Props> = ({
  title,
  message,
  continueButton,
  abortButton,
  isShown,
  abortsOnBackgroundClick,
}) => {
  const backgroundClickHandler = () => {
    if (abortsOnBackgroundClick ?? true) {
      abortButton?.onClick();
    }
  };

  return (
    <FanstaDialog isShown={isShown} onBackgroundClick={backgroundClickHandler}>
      <p className={styles.dialogBoxTitle}>{title}</p>
      <pre className={styles.dialogBoxText}>{message}</pre>
      <div className={styles.dialogBoxButton}>
        {abortButton && (
          <Button
            className={classNames(styles.cancel, styles.halfWidth)}
            styleType={continueButton?.styleType ?? "outlined"}
            colorType="secondary"
            onClick={abortButton.onClick}
            disabled={abortButton.isDisabled ?? false}
          >
            {abortButton.label}
          </Button>
        )}
        <Button
          className={classNames(
            styles.accept,
            abortButton === undefined ? styles.fullWidth : styles.halfWidth
          )}
          onClick={continueButton.onClick}
          styleType={abortButton?.styleType ?? "filled"}
          colorType={continueButton?.colorType ?? "primary"}
          disabled={continueButton.isDisabled ?? false}
        >
          {continueButton.label}
        </Button>
      </div>
    </FanstaDialog>
  );
};
