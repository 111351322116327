import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  cancelFeePercentage: number;
};
export const ReservationConfirmationAboutCancel: FC<Props> = ({
  cancelFeePercentage,
}) => (
  <div className={styles.aboutCancel}>
    <div className={styles.aboutCancelTitle}>キャンセルに関する注意事項</div>
    <ul className={styles.aboutCancelText}>
      <li>
        リクエスト予約が承認後にキャンセルするとキャンセルポリシーが適用されます。
      </li>
      <li>
        キャンセルポリシーとは別に、キャンセル手数料（返金金額の
        {cancelFeePercentage}%）が発生する場合がございます。
      </li>
      <li>
        キャンセル料やキャンセル手数料が発生した場合は、返金金額からそれらを差し引いた金額をご返金いたします。
      </li>
    </ul>
  </div>
);
