import { Text } from "@components/Text";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};
export const ReservationDetailItem: FC<Props> = ({ title, children }) => (
  <div className={styles.item}>
    <Text size={16} bold>
      {title}
    </Text>
    <div>{children}</div>
  </div>
);
export const ReservationDetailItemSingleValue: FC<{ value: string }> = ({
  value,
}) => (
  <Text size={16} className={styles.itemValue} preWrap>
    {value}
  </Text>
);
