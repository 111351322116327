import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  label: string;
  value: PaymentMethodInputType["pointsToApplyType"];
  children?: ReactNode;
};

const ReservationPaymentPointsOption: FC<Props> = ({
  label,
  value,
  children,
}) => {
  const { register } = useFormContext<PaymentMethodInputType>();
  return (
    <div className={styles.radio}>
      <label>
        <input type="radio" {...register("pointsToApplyType")} value={value} />

        <div className={styles.label}>
          <div className={styles.pointLabel}>{label}</div>
          <div className={classNames(styles.specify)}>{children}</div>
        </div>
      </label>
    </div>
  );
};

export default ReservationPaymentPointsOption;
