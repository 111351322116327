import type { UsersReservationPlanCancelPolicyRefundPercentageType } from "@graphql/__generated__/types";

export const buildCancelPolicyText = (
  cancelPolicyRefundPercentage: UsersReservationPlanCancelPolicyRefundPercentageType
) => {
  return [
    `当日キャンセル：${cancelPolicyRefundPercentage.onTheDay}%`,
    `前日キャンセル：${cancelPolicyRefundPercentage.before1Day}%`,
    `2日前キャンセル：${cancelPolicyRefundPercentage.before2Days}%`,
    `3日前キャンセル：${cancelPolicyRefundPercentage.before3Days}%`,
    "",
    "※リクエスト予約の承認後にキャンセルすると適用されます。",
    "※キャンセルポリシーとは別に、キャンセル手数料が発生する場合がございます。",
  ].join("\n");
};
