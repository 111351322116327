import ReservationPaymentPointsOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentPointsOption";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  applicablePoints: number;
};
const ReservationPaymentPointsAll: FC<Props> = ({ applicablePoints }) => {
  return (
    <ReservationPaymentPointsOption
      label={"全てのポイントを利用"}
      value={"all"}
    >
      <div className={styles.applicablePoints}>
        利用可能ポイント{" "}
        <span>
          <em>{applicablePoints.toLocaleString()}</em>
        </span>{" "}
        pt
      </div>
    </ReservationPaymentPointsOption>
  );
};

export default ReservationPaymentPointsAll;
