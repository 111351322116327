import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
};
export const ReservationConfirmationRow: FC<Props> = ({ title, children }) => (
  <li className={styles.othersItem}>
    <div className={styles.othersItemTitle}>{title}</div>
    <div className={styles.othersItemBody}>{children}</div>
  </li>
);
