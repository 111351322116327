import { useState } from "react";
import { createContainer } from "unstated-next";
import type { NameInputForm } from "@components/NameInput";
import type { PhoneNumberInputForm } from "@components/PhoneNumberInput";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import { ReservationPaymentTypeEnum } from "./__generated__/fragments";
import type {
  UseRequestReservation_ReservationConfigShopFragment as ReservationConfigShopFragment,
  UseRequestReservation_ReservationFrameFragment as ReservationFrameFragment,
} from "./__generated__/fragments";
import type { RequestReservation_ReservationFramesByDateOrMatchQuery as ReservationFramesByDateOrMatchQuery } from "../../pages/shops/[uuid]/request_reservation/__generated__/queries";
import type React from "react";

type CreditCard = {
  last4: string;
  brand: string;
};

type RequestReservation = {
  shop: ReservationConfigShopFragment | undefined;
  reservationFrame: NonNullable<ReservationFrameFragment[]>[0] | undefined;
  supportingTeamId: number | undefined;
  comesAt: string | undefined;
  personNum: number | undefined;
  paymentMethod: PaymentMethodInputType["method"] | undefined;
  confirmationToken: string | undefined;
  creditCard: CreditCard | undefined;
  savesNewCard: boolean;
  pointsToApply: number | undefined;
} & NameInputForm &
  PhoneNumberInputForm;

type RequestReservationContextProps = {
  requestReservation: RequestReservation;
  requestReservationFramesForGA: ReservationFramesByDateOrMatchQuery["reservationFramesByDateOrMatch"];
  setRequestReservation: React.Dispatch<
    React.SetStateAction<RequestReservation>
  >;
  setRequestReservationFramesForGA: React.Dispatch<
    React.SetStateAction<
      ReservationFramesByDateOrMatchQuery["reservationFramesByDateOrMatch"]
    >
  >;
  resetRequestReservation: () => void;
  resetRequestReservationFramesForGA: () => void;
  paymentAmount: number;
  paymentType: ReservationPaymentTypeEnum;
};

const defaultValue = {
  shop: undefined,
  reservationFrame: undefined,
  supportingTeamId: undefined,
  comesAt: undefined,
  personNum: undefined,
  paymentMethod: undefined,
  confirmationToken: undefined,
  creditCard: undefined,
  firstName: "",
  lastName: "",
  firstNameKana: "",
  lastNameKana: "",
  phoneNumber: "",
  savesNewCard: false,
  pointsToApply: undefined,
};

const useRequestReservation = (): RequestReservationContextProps => {
  const [requestReservation, setRequestReservation] =
    useState<RequestReservation>(defaultValue);
  const [requestReservationFramesForGA, setRequestReservationFramesForGA] =
    useState<
      ReservationFramesByDateOrMatchQuery["reservationFramesByDateOrMatch"]
    >([]);

  const resetRequestReservation = () => {
    setRequestReservation(defaultValue);
  };

  const resetRequestReservationFramesForGA = () => {
    setRequestReservationFramesForGA([]);
  };

  const paymentAmount =
    (requestReservation.reservationFrame?.reservationPlan.totalFee ?? 0) *
    (requestReservation.personNum ?? 0);
  const paymentType =
    requestReservation.paymentMethod === StaticPaymentMethodEnum.OnSite
      ? ReservationPaymentTypeEnum.OnSite
      : ReservationPaymentTypeEnum.OnSystem;

  return {
    requestReservation,
    requestReservationFramesForGA,
    setRequestReservation,
    setRequestReservationFramesForGA,
    resetRequestReservation,
    resetRequestReservationFramesForGA,
    paymentAmount,
    paymentType,
  };
};

const RequestReservation = createContainer(useRequestReservation);

export default RequestReservation;
