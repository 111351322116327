import { useFormContext } from "react-hook-form";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  onClick?: () => void;
  children: ReactNode;
};

const TermsConfirmedCheckbox: FC<Props> = ({ onClick, children }) => {
  const { register } = useFormContext();
  return (
    <>
      <div className={styles.agreement}>
        <label className={styles.check}>
          <input
            type="checkbox"
            {...register("isTermsConfirmed")}
            onClick={onClick}
          />
          <div className={styles.checkText}>{children}</div>
        </label>
      </div>
    </>
  );
};

export default TermsConfirmedCheckbox;
