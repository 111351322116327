import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

const LoginRequiredLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={"l-main__innerWidth"}>{children}</div>
    </div>
  );
};

export default LoginRequiredLayout;
