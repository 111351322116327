import ReservationPaymentPointsOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentPointsOption";
import type { FC } from "react";

const ReservationPaymentPointsNone: FC = () => {
  return (
    <ReservationPaymentPointsOption
      label={"ポイントを利用しない"}
      value={"none"}
    />
  );
};

export default ReservationPaymentPointsNone;
