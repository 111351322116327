import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import { MINIMUM_STRIPE_PAYMENT_AMOUNT } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import ReservationPaymentPointsOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentPointsOption";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  paymentAmount: number;
  applicablePoints: number;
};

const ReservationPaymentPointsSome: FC<Props> = ({
  applicablePoints,
  paymentAmount,
}) => {
  const {
    register,
    unregister,
    control,
    trigger,
    setFocus,
    setValue,
    formState: { errors },
  } = useFormContext<PaymentMethodInputType>();
  const selectedPointsToApplyType = useWatch({
    control,
    name: "pointsToApplyType",
  });
  const isSelected = selectedPointsToApplyType === "some";

  useEffect(() => {
    if (isSelected) {
      register("pointsToApply", validation);
      trigger("pointsToApply");
      setFocus("pointsToApply");
    } else {
      unregister("pointsToApply");
    }
  }, [isSelected]);

  const validation = {
    required: true,
    max: {
      value: applicablePoints,
      message: "ご利用可能なポイント数を超えています。",
    },
    min: {
      value: 1,
      message: "1以上の数値を入力してください。",
    },
    validate: (value: number | undefined) => {
      if (value === undefined) {
        return;
      }

      const remainingAmount = paymentAmount - value;
      if (
        remainingAmount > 0 &&
        remainingAmount < MINIMUM_STRIPE_PAYMENT_AMOUNT
      ) {
        return "お支払いの一部にポイントを利用する場合は、決済金額を50円未満にできません。";
      }
    },
    pattern: {
      value: /^[0-9]+$/,
      message: "数値を入力してください。",
    },
  };

  const errorMessage = errors["pointsToApply"]?.message;

  return (
    <ReservationPaymentPointsOption
      label={"ポイントの一部を利用"}
      value={"some"}
    >
      <div className={classNames(styles.specifyInput)}>
        <input
          inputMode={"numeric"}
          placeholder="利用するポイント数を入力"
          className={classNames(errorMessage && styles.error)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur();
            }
          }}
          // type="number"を使うとSafariで数字以外入力できるが取得はできず厄介なので、onInputで制御する
          onInput={(e) => {
            e.currentTarget.value = e.currentTarget.value.replace(
              /[^0-9]/g,
              ""
            );
            const inputType =
              "inputType" in e.nativeEvent && e.nativeEvent.inputType;
            // 全角モードで半角数字を入力した場合は即確定させるためにblur/focusを行う
            // SEE: https://github.com/mixi-lx-sys/fansta-doc/issues/5523
            if (inputType === "insertCompositionText") {
              e.currentTarget.blur();
              e.currentTarget.focus();
            }
          }}
          onFocus={() => setValue("pointsToApplyType", "some")}
          {...register("pointsToApply")}
        />
        <div>pt</div>
      </div>

      {errorMessage && (
        <div className={styles.invalidMessage}>{errorMessage}</div>
      )}
    </ReservationPaymentPointsOption>
  );
};

export default ReservationPaymentPointsSome;
