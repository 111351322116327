import {
  useRequestReservationConfirm_OnSystemPaymentCancelFeeQuery,
  useRequestReservationConfirm_PointsToGiveQuery,
} from "@pages/shops/[uuid]/request_reservation/confirm/__generated__/queries";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Button } from "@components/Button";
import { CenterAlignedTitle } from "@components/CenterAlignedTitle";
import { ReservationHead } from "@components/CommonHead";
import CommonLayout from "@components/CommonLayout";
import { CreditCardBrandIcon } from "@components/CreditCardBrandIcon";
import { LeftAlignedTitle } from "@components/LeftAlignedTitle";
import LoginRequiredLayout from "@components/LoginRequiredLayout";
import TermsConfirmedCheckbox from "@components/TermsConfirmedCheckbox";
import { Text } from "@components/Text";
import Toaster from "@components/Toaster";
import { RequestReservationSteps } from "@components/pages/shops/[uuid]/request_reservation/RequestReservationSteps";
import { ReservationConfirmationAboutCancel } from "@components/pages/shops/[uuid]/request_reservation/confirm/ReservationConfirmationAboutCancel";
import { ReservationConfirmationCard } from "@components/pages/shops/[uuid]/request_reservation/confirm/ReservationConfirmationCard";
import { ReservationConfirmationNotes } from "@components/pages/shops/[uuid]/request_reservation/confirm/ReservationConfirmationNotes";
import { ReservationConfirmationRow } from "@components/pages/shops/[uuid]/request_reservation/confirm/ReservationConfirmationRow";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import {
  ReservationPaymentTypeEnum,
  ReservationPlanAllowedPaymentTypeEnum,
} from "@graphql/__generated__/types";
import Auth from "@hooks/useAuth";
import Message, { PredefinedMessages } from "@hooks/useMessage";
import RequestReservation from "@hooks/useRequestReservation";
import UserPolicyConfirmationModalsController from "@hooks/useUserPolicyConfirmationModalsController";
import { paths } from "@libs/paths";
import { convertDayjs } from "@libs/utils/date";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import { rollbar } from "@libs/utils/rollbar";
import { stripePromise } from "@libs/utils/stripe";
import {
  useRequestReservation_CompleteReservationRequestMutation,
  useRequestReservation_RequestReservationMutation,
} from "./__generated__/mutations";
import styles from "./index.module.css";
import type { RequestReservation_RequestReservationMutation } from "./__generated__/mutations";
import type { FetchResult } from "@apollo/client";
import type { NextPage } from "next";

export const toUtcDatetime = (dateStr: string, timeStr: string) => {
  let date = convertDayjs(dateStr);
  const fanstaHour = Number(timeStr.slice(0, 2));
  date = date.set("hour", fanstaHour % 24);
  date = date.set("minute", Number(timeStr.slice(3, 5)));
  if (fanstaHour >= 24) {
    date = date.add(1, "day");
  }
  return date.toISOString();
};

export type RequestReservationConfirmInputForm = {
  isTermsConfirmed: boolean;
};

const RequestReservationConfirm: NextPage = () => {
  const router = useRouter();
  const shopUuid =
    typeof router.query.uuid === "string" ? router.query.uuid : "";

  const methods = useForm<RequestReservationConfirmInputForm>({
    mode: "onBlur",
  });
  const { control, handleSubmit } = methods;

  const isTermsConfirmedInput = useWatch({
    control,
    name: "isTermsConfirmed",
  });

  const { loadFanstaUser } = Auth.useContainer();
  const { addMessage } = Message.useContainer();

  const [requestReservationMutation] =
    useRequestReservation_RequestReservationMutation();
  const [completeReservationRequestMutation] =
    useRequestReservation_CompleteReservationRequestMutation();

  const {
    requestReservation: {
      reservationFrame,
      comesAt,
      lastNameKana,
      lastName,
      firstNameKana,
      firstName,
      shop,
      supportingTeamId,
      personNum,
      phoneNumber,
      paymentMethod,
      confirmationToken,
      creditCard,
      savesNewCard,
      pointsToApply,
    },
    paymentAmount,
    paymentType,
    requestReservationFramesForGA,
    resetRequestReservation,
    resetRequestReservationFramesForGA,
  } = RequestReservation.useContainer();

  const match = reservationFrame?.match;
  const hasSupportingTeam = match?.homeTeam && match.awayTeam;
  const plan = reservationFrame?.reservationPlan;
  const showPaymentAmount = paymentAmount > 0;
  const skippedPaymentMethods =
    plan?.allowedPaymentType === ReservationPlanAllowedPaymentTypeEnum.OnSite;

  const { data } = useRequestReservationConfirm_PointsToGiveQuery({
    variables: { personNum: personNum ?? 0 },
    skip: !personNum,
  });
  const pointsToGive = data?.pointsToGive;

  const { data: cancelFeeData } =
    useRequestReservationConfirm_OnSystemPaymentCancelFeeQuery();
  const cancelFeePercentage =
    cancelFeeData?.onSystemPaymentCancelFee.percentage;

  // リロードなどでContextが失われたら予約入力ページに戻す
  useEffect(() => {
    if (!shopUuid) {
      return;
    }
    if (!reservationFrame) {
      router.push(paths.shopRequestReservation(shopUuid));
    }
  }, [shopUuid]);

  const userPolicyConfirmationModals =
    UserPolicyConfirmationModalsController.useContainer();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRequestReservationResult = async (
    result: FetchResult<RequestReservation_RequestReservationMutation>
  ) => {
    const reservationResult = result.data?.requestReservationV3;
    if (
      reservationResult?.__typename === "UsersPayloadsRequestReservationPayload"
    ) {
      await handleReservationPayload(
        reservationResult.needsConfirmation,
        reservationResult.reservationUuid,
        reservationResult.clientSecret
      );
      return;
    }
    await handleError(reservationResult);
  };

  const handleReservationPayload = async (
    needsConfirmation: boolean,
    reservationUuid: string,
    clientSecret: string | null | undefined
  ) => {
    if (needsConfirmation) {
      if (!clientSecret) {
        throw new Error("clientSecret is not found");
      }
      const { success } = await handleConfirmation(
        reservationUuid,
        clientSecret
      );
      if (!success) {
        return;
      }
    }
    requestReservationFramesForGA.forEach((frame) => {
      GTMEvent(
        GTMEvents.reservationFrameOption(
          reservationUuid,
          frame.reservationPlan.name,
          frame.reservationPlan.description,
          frame.state
        )
      );
    });
    GTMEvent(GTMEvents.requestReservation(!!reservationFrame?.match));
    resetRequestReservation();
    resetRequestReservationFramesForGA();
    loadFanstaUser();
    await router.push({
      pathname: paths.shopRequestReservationComplete(shopUuid),
      query: { id: reservationUuid },
    });
    return;
  };

  const handleConfirmation = async (
    reservationUuid: string,
    clientSecret: string
  ) => {
    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error("stripe is not found");
    }
    const { error } = await stripe.handleNextAction({
      clientSecret,
    });

    if (error) {
      rollbar?.error(error);
      addMessage(PredefinedMessages.requestReservationPaymentError);
      await router.push({
        pathname: paths.shopRequestReservation(shopUuid),
      });
      return { success: false };
    }
    await completeReservationRequestMutation({
      variables: {
        reservationUuid,
      },
    });
    return { success: true };
  };

  const handleError = async (
    reservationResult: RequestReservation_RequestReservationMutation["requestReservationV3"]
  ) => {
    switch (reservationResult?.__typename) {
      case "RequestReservationResultV3_ShopReservationStatusInactiveError":
        addMessage(PredefinedMessages.requestReservationNoAvailableFramesError);
        await moveToShopDetail();
        break;
      case "RequestReservationResultV3_ReservationPlanInconsistentError":
        addMessage(PredefinedMessages.requestReservationPlanInconsistentError);
        await moveToShopDetail();
        break;
      case "RequestReservationResultV3_ReservationPaymentError":
        addMessage(PredefinedMessages.requestReservationPaymentError);
        await router.push({
          pathname: paths.shopRequestReservation(shopUuid),
        });
        break;
      default:
        addMessage(PredefinedMessages.requestReservationFailed);
        await moveToShopDetail();
        break;
    }
  };

  const moveToShopDetail = async () => {
    resetRequestReservation();
    resetRequestReservationFramesForGA();
    await router.push({
      pathname: paths.shopDetail(shopUuid),
    });
  };

  const onSubmit = async () => {
    if (
      !reservationFrame ||
      !comesAt ||
      !personNum ||
      !lastName ||
      !firstName ||
      !lastNameKana ||
      !firstNameKana ||
      !resetRequestReservation ||
      !resetRequestReservationFramesForGA
    ) {
      return;
    }

    setIsSubmitting(true);
    // 1つ以上のポリシーに同意が必要な場合はtrueが返ってくるので予約せずにreturnする
    if (await userPolicyConfirmationModals.showIfNeeded()) {
      setIsSubmitting(false);
      return;
    }

    const isPaymentMethodId =
      paymentMethod &&
      paymentMethod !== StaticPaymentMethodEnum.OnSite &&
      paymentMethod !== StaticPaymentMethodEnum.NewCard;
    try {
      const result = await requestReservationMutation({
        variables: {
          input: {
            reservationFrameId: reservationFrame.id,
            comesAt: toUtcDatetime(reservationFrame.date, comesAt),
            reservationSupportingTeam: hasSupportingTeam
              ? { supportingTeamId: supportingTeamId || null }
              : null,
            personNum,
            lastName,
            lastNameKana,
            firstName,
            firstNameKana,
            tel: phoneNumber,
            reservationPlanLockVersion:
              reservationFrame.reservationPlan.lockVersion,
            paymentType,
            confirmationToken,
            paymentMethodId: isPaymentMethodId ? paymentMethod : null,
            savesPaymentMethod: savesNewCard,
            pointsToApply,
          },
        },
      });
      await handleRequestReservationResult(result);
    } catch (error) {
      addMessage(PredefinedMessages.commonError);
      console.error(error);
      // 500エラーの場合は500エラーの画面遷移をしないように同じ画面に留まるようにする
      router.replace(router.asPath);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CommonLayout hasFooter footerPC>
      <ReservationHead title="リクエスト予約" shop={shop} />
      {reservationFrame && (
        <LoginRequiredLayout>
          <Toaster messageFilter={[PredefinedMessages.commonError]} />
          <CenterAlignedTitle label="リクエスト予約" />
          <RequestReservationSteps
            step={3}
            showsStep2={!skippedPaymentMethods}
          />
          <div className={styles.title}>
            <LeftAlignedTitle label="ご予約確認" />
          </div>
          <ReservationConfirmationCard />
          <div className={styles.others}>
            <ul className={styles.othersList}>
              {plan && plan.totalFee > 0 && (
                <>
                  <ReservationConfirmationRow title={"お支払い方法"}>
                    {creditCard ? (
                      <div className={styles.othersItemCard}>
                        <CreditCardBrandIcon brand={creditCard.brand} />
                        <div className={styles.othersItemCardNumber}>
                          **** {creditCard.last4}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.othersItemCard}>
                        {paymentType === ReservationPaymentTypeEnum.OnSite
                          ? "現地でのお支払い"
                          : "ポイント利用"}
                      </div>
                    )}
                    {plan?.allowedPaymentType !==
                      ReservationPlanAllowedPaymentTypeEnum.OnSite && (
                      <Link
                        href={paths.shopRequestReservationPaymentMethods(
                          shopUuid
                        )}
                      >
                        変更
                      </Link>
                    )}
                  </ReservationConfirmationRow>
                  {showPaymentAmount && (
                    <ReservationConfirmationRow title={"小計"}>
                      {paymentAmount.toLocaleString()}円
                    </ReservationConfirmationRow>
                  )}
                  {pointsToApply !== undefined && (
                    <ReservationConfirmationRow title={"ポイント利用"}>
                      {pointsToApply.toLocaleString()}pt
                      <Link
                        href={paths.shopRequestReservationPaymentMethods(
                          shopUuid
                        )}
                      >
                        変更
                      </Link>
                    </ReservationConfirmationRow>
                  )}
                  {showPaymentAmount && (
                    <ReservationConfirmationRow title={"お支払い金額"}>
                      <p className={styles.othersItemTotal}>
                        {(
                          paymentAmount - (pointsToApply ?? 0)
                        ).toLocaleString()}
                        円
                      </p>
                    </ReservationConfirmationRow>
                  )}
                </>
              )}

              {pointsToGive && (
                <ReservationConfirmationRow title={"獲得ポイント"}>
                  ご来店で<em>{pointsToGive}pt</em>獲得
                  {/*TODO: [事前決済] ポイント還元率の変更 のPBIで実装する*/}
                  {/*<a href="#" className={styles.pointBreakdownLink}>*/}
                  {/*  内訳*/}
                  {/*</a>*/}
                </ReservationConfirmationRow>
              )}
            </ul>

            {pointsToGive && (
              <div className={styles.pointNote}>
                ※ご来店日から5日以内に付与されます
              </div>
            )}
          </div>

          {paymentType === ReservationPaymentTypeEnum.OnSystem &&
            cancelFeePercentage && (
              <div className={styles.cancelNote}>
                <ReservationConfirmationAboutCancel
                  cancelFeePercentage={cancelFeePercentage}
                />
                <ReservationConfirmationNotes
                  cancelFeePercentage={cancelFeePercentage}
                />
              </div>
            )}

          <div className={styles.padding} />
          <div className={styles.buttons}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <TermsConfirmedCheckbox>
                  <Text size={14}>
                    <Link href={paths.reservationTerms} target="_blank">
                      予約サービス利用規約
                    </Link>
                    、プラン内容
                    {(plan?.notes || plan?.cancelPolicy) &&
                      "、注意事項・キャンセルポリシー"}
                    に同意
                  </Text>
                </TermsConfirmedCheckbox>

                <div className={styles.btnWrapper}>
                  <Button
                    className={classNames(styles.btn)}
                    type="submit"
                    disabled={!isTermsConfirmedInput || isSubmitting}
                    styleType="filled"
                    colorType="primary"
                  >
                    リクエスト予約を確定する
                  </Button>
                </div>
              </form>
            </FormProvider>
            <div className={classNames(styles.link)}>
              <Link
                href={
                  skippedPaymentMethods
                    ? paths.shopRequestReservation(shopUuid)
                    : paths.shopRequestReservationPaymentMethods(shopUuid)
                }
              >
                戻って予約内容を修正する
              </Link>
            </div>
          </div>
        </LoginRequiredLayout>
      )}
    </CommonLayout>
  );
};

export default RequestReservationConfirm;
